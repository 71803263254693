<template>
	<div class="inner">
		<img src="../assets/tools-bg.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">实用工具</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Utility Tool</div>
			</div>
			<div class="tools-list tr3 pt30 hover pb24 over el-border-bottom-b" v-for="(item,index) in toolList" :key="item.id">
				<div class="fl ft18 tr3" @click="handleToolDetails(item.id)">{{item.title}}</div>
				<div class="fr"><i class="el-icon-arrow-right tr3"></i></div>
			</div>
			<div class="tc pt50">
				<el-pagination background layout="prev, pager, next" :total="total"  :current-page="queryParams.pageNum" :page-size="queryParams.pageSize"  @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { toolPageList } from '@/api/index.js'
	export default {
		data() {
			return{
				queryParams: {
					title:'', //
					pageNum: 1,
					pageSize: 10
				},
				total: 0, // 车型总记录数
				toolList: []  // 实用工具列表
			}
		},
		created() {
			this.getToolsPage()
		},
		methods:{
			getToolsPage(){
				toolPageList(this.queryParams).then(res => {
					if(res.code === 200){
						this.toolList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			handleCurrentChange(val){
				this.queryParams.pageNum = val
				this.getToolsPage()
			},
			handleToolDetails(id){
				this.$router.push({
					path: '/tool-details',
					query:{
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.tools-list:hover{
		color: #b4232f;
		transform: translateX(4px);
	}
	.container{margin-bottom: 200px;}
	.el-border-bottom-b{border-bottom: 1px solid rgba(0,0,0,.03);}
</style>
